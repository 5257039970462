<template>
  <div>
    Page Not Found
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>